<template>
   <v-dialog v-model="dialogCompose" max-width="500">
      <v-card class="elevation-12" flat>
         <v-toolbar dark color="primary">
            <v-toolbar-title>Přihlášení zákazníka</v-toolbar-title>
         </v-toolbar>
         <!--    <v-card-title class="headline" primary-title>
                  Login
                </v-card-title> -->

         <v-card-text class="pt-3">
            <form ref="form" @submit.prevent="isRegister ? register() : login()">
               <v-text-field v-model="userName" name="email" label="E-mail" type="email" required autofocus
                  :rules="[rules.required, rules.email]"></v-text-field>
               <v-text-field v-model="password" name="password" label="Heslo" type="password" required></v-text-field>
               <a href="#" class="text-body-2 font-weight-regular">Zapomenuté heslo?</a>
               <v-text-field v-if="isRegister" v-model="confirmPassword" name="confirmPassword" label="Confirm Password"
                  type="password" placeholder="confirm password" required></v-text-field>
               <div class="red--text"> {{ errorMessage }}</div>
               <div class="mt-1 mb-1">
                  <p class="text-body-2">Nemáte ještě účet? <a href="#">Registrovat</a></p>
               </div>
               <v-btn type="submit" class="mt-2" color="primary" variant="outlined" block value="log in">{{
      isRegister ?
         stateObj.register.name : stateObj.login.name }}</v-btn>

            </form>
         </v-card-text>
      </v-card>
   </v-dialog>
</template>

<script>

export default {
   name: "Login",
   data() {
      return {
         dialogCompose: false,
         rules: window.validationRules.getRules(),
         userName: null,
         password: null,
         confirmPassword: null,
         isRegister: false,
         errorMessage: null,
         stateObj: {
            register: {
               name: 'Register',
               message: 'Aleady have an Account? Click here Login.'
            },
            login: {
               name: 'Login',
               message: 'Don\'t have an account? Click here Register.'
            }
         }
      };
   },
   methods: {
      visible() {
         this.dialogCompose = true;
      },
      login() {
         let clientId = process.env.VUE_APP_CLIENT_ID;
         let userName = this.userName;
         let password = this.password;
         this.$store
            .dispatch("login", { userName, password, clientId })
            .then((response) => this.onLoginSuccess(response))
            .catch((err) => {
               this.onLoginFail(err);
            });
      },
      onLoginSuccess() {
         this.dialogCompose = false;
         this.$router.push("/account");
      },
      onLoginFail(err) {
         if (err && err.errors) {
            err.errors.forEach((element) => {
               this.errors.add({
                  scope: "login",
                  field: element.field,
                  msg: element.msg,
               });
            });
         }
      },
      register() {
         if (this.password == this.confirmPassword) {
            this.isRegister = false;
            this.errorMessage = "";
            this.$refs.form.reset();
         }
         else {
            this.errorMessage = "password did not match"
         }
      }
   },
   computed: {
      toggleMessage: function () {
         return this.isRegister ? this.stateObj.register.message : this.stateObj.login.message
      }
   }
};
</script>