<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn class="flexcol" plain depressed v-bind="attrs" v-on="on">
                <div>
                    <v-avatar size="40">
                        <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John">
                    </v-avatar>                   
                </div>
               <!--  <div>
                    {{ $t("account.login") }}
                </div> -->
            </v-btn>
        </template>
        <v-list>
            <v-list-item>
                <v-list-item-title>{{ $t("account.login") }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>

</template>
<script>
import { LOCALES } from "@/i18n";

export default {
    name: "HeaderUser",
    props: [],
    data() {
        return {
            drawer: false,
            menu: false,
            locales: LOCALES,
            activeLocale: null,
        };
    }
}

</script>

<style lang="scss">
.flexcol .v-btn__content {
    flex-direction: column;
}
</style>