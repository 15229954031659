import Vue from "vue"
import Vuex from "vuex"
import VuexPersistence from "vuex-persist"
import eshopModule from './modules/eshop.js';
import authModule from './modules/auth.js';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  key: 'eshop',
  storage: window.localStorage,
  reducer: state => ({ eshopModule: { added: state.eshopModule.added, user: state.eshopModule.user, lang: state.eshopModule.lang } }),
  modules: ["eshopModule"]
});

export default new Vuex.Store({
  modules: { eshopModule, authModule },
  plugins: [vuexLocal.plugin]
});