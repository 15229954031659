const state =
{
    status: '',
    token: localStorage.getItem('token') || '',
    menu: []
};

const getters = {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
};

const actions = {
    login({ commit }, user) {        
        return new Promise((resolve, reject) => {
            commit('auth_request');
            window.axios({
                url: process.env.VUE_APP_ROOT_API + process.env.VUE_APP_API_ROOT + '/account/login', data: user, method: 'POST'
            }).then(resp => {
                const token = resp.data.token;
                localStorage.setItem('token', token);
                commit('auth_success', { token });           
                resolve(resp)
            }).catch(err => {
                commit('auth_error')
                localStorage.removeItem('token')
                reject(err)
            })
        })
    },
    register({ commit }, user) {
        return new Promise((resolve, reject) => {
            commit('auth_request')
            window.axios({ url: '/register', data: user, method: 'POST' })
                .then(resp => {
                    const token = resp.data.token
                    localStorage.setItem('token', token)

                    window.axios.defaults.headers.common['Authorization'] = token

                    commit('auth_success', token)
                    resolve(resp)
                }).catch(err => {

                    commit('auth_error', err)
                    localStorage.removeItem('token')
                    reject(err)
                })
        })
    },
    recover({ commit }, email) {
        return new Promise((resolve, reject) => {
            commit('auth_request')
            const urlForm = window.location.origin + "/setpassword"
            window.axios({ url: 'account/recoverpassword', data: { userName: email, clientId: process.env.VUE_AAP_CLIENT_ID, url: urlForm }, method: 'POST' })
                .then(resp => {
                    //commit('auth_success', token, user)
                    resolve(resp)
                }).catch(err => {
                    reject(err)
                })
        })
    },
    logout({ commit }) {
        return new Promise((resolve) => {
            commit('logout');
            localStorage.removeItem('token');
            delete window.axios.defaults.headers.common['Authorization'];
            resolve();
        })
    }
};

const mutations = {
    auth_menu(state, menu) {
        state.menu = menu;
    },
    auth_request(state) {
        state.status = 'loading'
    },
    auth_success(state, { token }) {
        state.status = 'success';
        state.token = token;
    },
    auth_error(state) {
        state.status = 'error'
    },
    auth_recover(state) {
        state.status = 'recover'
    },
    logout(state) {
        state.status = '';
        state.token = '';
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
}

export function requireAuth(to, from, next) {
/*    
    if (!isLoggedIn()) {
        store.dispatch('logoutUser');
        localStorage.removeItem('token');
        localStorage.removeItem('expiresIn');
        next({
            path: '/',
            query: { redirect: to.fullPath }
        });
    } else {
        next();
    }
        */

    next();
}

