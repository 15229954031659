<template>
  <div>
    <h3>{{ $t("account.orders") }}</h3>
    <p></p>
    <v-data-table :headers="headers" :items="items" :page="page" :options.sync="options"
      :server-items-length="totalCount" :loading="loading" :items-per-page="itemsPerPage" hide-default-footer>
      <template v-slot:item.orderDate="{ item }">
        <span>{{ new Date(item.orderDate).toLocaleString() }}</span>
      </template>
      <template v-slot:item.orderState="{ item }">
        <v-chip color="green" label outlined small><span>{{ item.orderState }}</span></v-chip>
      </template>
      <template v-slot:item.paymentState="{ item }">
        <v-chip color="green" label outlined small><span>{{ item.paymentState }}</span></v-chip>
      </template>
      <template v-slot:item.paymentMethod="{  }">
        <img :src="require('../../assets/payment_methods/payment_icon_gpwebpay.png')" style="max-height: 40px;" /> 
      </template> 

      <template v-slot:item.totalWithVat="{ item }">
        <span>{{ item.totalWithVat.toLocaleString() }}</span>
      </template>
      <template v-slot:item.invoice="{ item }">
        <a v-if="item.invoiceUrl" target="_blank" :href="item.invoiceUrl">Doklad</a><span v-if="item.passUrl">&nbsp;|&nbsp;</span>
        <a v-if="item.passUrl" target="_blank" :href="item.passUrl">Vstupenka</a>
        
      </template>

      <template v-slot:bottom>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
          <v-text-field :model-value="itemsPerPage" class="pa-2" label="Items per page" type="number" min="-1" max="15"
            hide-details @update:model-value="itemsPerPage = parseInt($event, 10)"></v-text-field>
        </div>
      </template>
    </v-data-table>
    <v-pagination circle v-model="page" :length="pageCount" total-visible="10"></v-pagination>
  </div>
</template>

<script>
export default {
  name: 'Orders',
  props: [],
  components: {

  },
  data() {
    return {
      page: 1,
      totalCount: 0,
      itemsPerPage: 10,
      items: [],
      loading: true,
      options: {},
      headers: [
        {
          text: '# Číslo',
          align: 'start',
          sortable: false,
          value: 'number',
        },
        { 
          text: 'Datum', 
          value: 'orderDate', 
          sortable: false
         },
        { 
          text: 'Cena', 
          align: 'right',
          value: 'totalWithVat',
          sortable: false 
        },
        { 
          text: 'Stav obj.', 
          value: 'orderState',
          sortable: false 
        },        
        { 
          text: 'Stav platby', 
          value: 'paymentState', 
          sortable: false
        },
        { 
          text: 'Způsob platby', 
          value: 'paymentMethod',
          sortable: false 
        },
        { 
          text: '', 
          value: 'invoice',
          sortable: false 
        },
      ],
    }
  },
  watch: {
    page: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  computed: {
    pageCount() {
      return Math.ceil(this.totalCount / this.itemsPerPage)
    },
  },
  methods: {
    getDataFromApi() {
      this.loading = true      
      window.axios
        .get(process.env.VUE_APP_ROOT_API + process.env.VUE_APP_API_ROOT + "/customeraccount/orders?pageIndex=" + this.page + "&pageSize=" + this.itemsPerPage)
        .then(response => {
          this.items = response.data.items;
          this.totalCount = response.data.totalCount
          this.loading = false
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
}
</script>
<style>
.v-data-table-header {
  background-color: #e9531c; 
  
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: white !important; ;
}
</style>
