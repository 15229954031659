import Vue from 'vue'
import Router from 'vue-router'
import VueMeta from 'vue-meta'

import Category from '../pages/Category'
import Product from '../pages/Product'
import CheckCustomer from '../pages/CheckCustomer'
import Checkout from '../pages/Checkout'
import CheckoutSuccess from "../pages/CheckoutSuccess";
import Cart from '../pages/Cart'
import PaymentFeedback from '../pages/PaymentFeedback'
import CookiePolicy from '../pages/CookiePolicy'
import SeasonTicketRegistration from '../pages/SeasonTicketRegistration'

import Account from '../pages/Account'
import Orders from '../components/Account/Orders'
import Tickets from '../components/Account/Tickets'
import Profile from '../components/Account/Profile'
import Home from '../components/Account/Home'

Vue.use(VueMeta);
Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        { path: '/', component: Category, name: 'Homepage' },
        { path: '/category/:alias', component: Category, name: 'Category' },
        { path: '/product/:alias', component: Product, name: 'Product' },
        { path: '/check-customer', component: CheckCustomer, name: 'CheckingCustomer' },
        { path: '/cart', component: Cart, name: 'Cart' },
        { path: '/checkout', component: Checkout, name: 'Checkout' },
        { path: '/checkout/success', component: CheckoutSuccess, name: 'Success' },
        { path: '/payment/feedback/:eshopId/', component: PaymentFeedback, name: 'PaymentFeedback' },
        { path: '/privacy', component: CookiePolicy, name: "CookiePolicy" },
        { path: '/neprenosne-permanentky/registrace/:id', component: SeasonTicketRegistration, name: 'CheckoutRegistration' },
        {
            path: '/account', component: Account, name: "Account", redirect: { name: 'Home' },
            children: [
                {
                    path: 'home',
                    name: "Home",
                    components: {
                        account: Home
                    },
                    //beforeEnter: requireAuth
                },
                {
                    path: 'vouchers',
                    name: "vouchers",
                    components: {
                        account: Home
                    },
                    //beforeEnter: requireAuth
                },

                {
                    path: 'orders',
                    name: "Orders",
                    components: {
                        account: Orders
                    },
                    //beforeEnter: requireAuth
                },
                {
                    path: 'tickets',
                    name: "Tickets",
                    components: {
                        account: Tickets
                    },
                    //beforeEnter: requireAuth
                },
                {
                    path: 'profile',
                    name: "Profile",
                    components: {
                        account: Profile
                    },
                    //beforeEnter: requireAuth
                },
                {
                    path: 'password',
                    name: "Password",
                    components: {
                        account: Home
                    },
                    //beforeEnter: requireAuth
                }
            ]
        },

    ],
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
})
