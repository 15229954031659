<template>
    <v-container fluid>
        <v-layout column>
            <v-card>
                <v-card-text>
                    <v-flex class="mb-4">
                        <v-avatar size="96" class="mr-4">
                            <!-- <img :src="'/avatars/avatar_' + (form.avatar.toLowerCase()) + '.png'" alt="Avatar"> -->
                        </v-avatar>
                        <v-btn @click="openAvatarPicker">Change Avatar</v-btn>
                    </v-flex>

                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field v-model="form.customer.firstName" label="Jméno" required
                                :rules="[rules.required]"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field v-model="form.customer.lastName" label="Příjmení" required
                                :rules="[rules.required]"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-text-field @blur="blur" v-model="form.customer.email" :label="$t('checkout.email')"
                                :rules="[rules.required, rules.email]" :error-messages="error" :loading="loading"
                                :error="error !== null" :append-icon="success ? 'mdi-check' : ''" type="email"
                                name="email"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-text-field v-model="form.customer.phone" label="Telefon" required
                                :rules="[rules.required]"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-checkbox class="mt-0" v-model="company" :label="$t('checkout.isCompany')"></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row v-if="company">
                        <v-col cols="12" md="12">
                            <v-text-field v-model="form.customer.companyName" :label="$t('checkout.company')" required
                                :rules="[rules.required]"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="company">
                        <v-col cols="12" md="6">
                            <v-text-field v-model="form.customer.companyCRN" :label="$t('checkout.singleVat')" required
                                :rules="[rules.required]"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field v-model="form.customer.companyVAT" :label="$t('checkout.companyVat')"
                                required></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="company">
                        <v-col cols="12" md="12">
                            <v-text-field v-model="form.customer.address.street" :label="$t('checkout.street')" required
                                :rules="[rules.required]"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="company">
                        <v-col cols="12" md="6">
                            <v-text-field v-model="form.customer.address.town" :label="$t('checkout.city')" required
                                :rules="[rules.required]"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field v-model="form.customer.address.zip" :label="$t('checkout.zip')" required
                                :rules="[rules.required]"></v-text-field>
                        </v-col>
                    </v-row>


                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" :loading="loading" @click.native="update">
                        <v-icon left dark>check</v-icon>
                        Save Changes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-layout>
        <!--   <avatar-picker
          v-model="showAvatarPicker"
          :current-avatar="form.avatar"
          @selected="selectAvatar"></avatar-picker> -->
    </v-container>
</template>

<script>
//import AvatarPicker from '~/components/AvatarPicker'

export default {
    data() {
        return {
            loading: false,
            user: {
                id: null,
                email: null,
                firstName: null,
                lastName: null,
                phone: null,
                companyName: null,
                companyCRN: null,
                companyVAT: null,
                street: null,
                town: null,
                zip: null,
                customerInterests: [],
                tenantName: null
            }
        }
    },
    methods: {
        loadData() {

        },
    }
}
</script>