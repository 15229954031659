<template>
  <div>
    <h3>{{ $t("account.tickets") }}</h3>
    <p></p>
    <v-data-table :headers="headers" :items="items" :page="page" :options.sync="options"
      :server-items-length="totalCount" :loading="loading" :items-per-page="itemsPerPage"  show-expand hide-default-footer>

      <template v-slot:item.createAt="{ item }">
        <span>{{ new Date(item.createAt).toLocaleString() }}</span>
      </template>

      <template v-slot:item.validFrom="{ item }">
        <span>{{ new Date(item.validFrom).toLocaleDateString() }}</span>
      </template>

      <template v-slot:item.validTo="{ item }">
        <span>{{ new Date(item.validTo).toLocaleDateString() }}</span>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip color="green" label outlined small><span>{{ item.status }}</span></v-chip>
      </template>

      <template v-slot:item.download="{ item }">
        <a v-if="item.passUrl" target="_blank" :href="item.passUrl">Vstupenka</a><span v-if="item.walletUrl">&nbsp;|&nbsp;</span>
        <a v-if="item.walletUrl" target="_blank" :href="item.walletUrl">Wallet</a>
      </template>
      
      <template v-slot:expanded-item="{ headers, item }">        
          <td :colspan="headers.length" class="pa-3" >
             <h4>Historie</h4>
              <table cellspacing="15">
                <tr>
                  <td>{{ new Date(item.createAt).toLocaleString()}}</td>
                  <td>Kredit 120Kč</td>
                  <td>+5 vstupů</td>
                </tr>
              </table>
          </td>
        
    </template>
      <template v-slot:bottom>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
          <v-text-field :model-value="itemsPerPage" class="pa-2" label="Items per page" type="number" min="-1" max="15"
            hide-details @update:model-value="itemsPerPage = parseInt($event, 10)"></v-text-field>
        </div>
      </template>
    </v-data-table>
    <v-pagination circle v-model="page" :length="pageCount" total-visible="10"></v-pagination>
  </div>
</template>

<script>
export default {
  name: 'Orders',
  props: [],
  components: {

  },
  data() {
    return {
      page: 1,
      totalCount: 0,
      itemsPerPage: 10,
      items: [],
      loading: true,
      options: {},
      headers: [
        {
          text: '# Číslo',
          align: 'start',
          sortable: false,
          value: 'number',
        },
        { 
          text: 'Vytvořen', 
          value: 'createAt', 
          sortable: false
         },
        { 
          text: 'Platný od',           
          value: 'validFrom',
          sortable: false 
        },
        { 
          text: 'Platný do',           
          value: 'validTo',
          sortable: false 
        },

        { 
          text: 'Vstupy', 
          value: 'entries',
          sortable: false 
        },        
        { 
          text: 'Stav', 
          value: 'status', 
          sortable: false
        },
        { 
          text: '', 
          value: 'download',
          sortable: false 
        },
      ],
    }
  },
  watch: {
    page: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  computed: {
    pageCount() {
      return Math.ceil(this.totalCount / this.itemsPerPage)
    },
  },
  methods: {
    getDataFromApi() {
      this.loading = true      
      window.axios
        .get(process.env.VUE_APP_ROOT_API + process.env.VUE_APP_API_ROOT + "/customeraccount/tickets?pageIndex=" + this.page + "&pageSize=" + this.itemsPerPage)
        .then(response => {
          this.items = response.data.items;
          this.totalCount = response.data.totalCount
          this.loading = false
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
}
</script>
