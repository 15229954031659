var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t("account.orders")))]),_c('p'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"page":_vm.page,"options":_vm.options,"server-items-length":_vm.totalCount,"loading":_vm.loading,"items-per-page":_vm.itemsPerPage,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.orderDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.orderDate).toLocaleString()))])]}},{key:"item.orderState",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"green","label":"","outlined":"","small":""}},[_c('span',[_vm._v(_vm._s(item.orderState))])])]}},{key:"item.paymentState",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"green","label":"","outlined":"","small":""}},[_c('span',[_vm._v(_vm._s(item.paymentState))])])]}},{key:"item.paymentMethod",fn:function(ref){return [_c('img',{staticStyle:{"max-height":"40px"},attrs:{"src":require('../../assets/payment_methods/payment_icon_gpwebpay.png')}})]}},{key:"item.totalWithVat",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.totalWithVat.toLocaleString()))])]}},{key:"item.invoice",fn:function(ref){
var item = ref.item;
return [(item.invoiceUrl)?_c('a',{attrs:{"target":"_blank","href":item.invoiceUrl}},[_vm._v("Doklad")]):_vm._e(),(item.passUrl)?_c('span',[_vm._v(" | ")]):_vm._e(),(item.passUrl)?_c('a',{attrs:{"target":"_blank","href":item.passUrl}},[_vm._v("Vstupenka")]):_vm._e()]}},{key:"bottom",fn:function(){return [_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('v-text-field',{staticClass:"pa-2",attrs:{"model-value":_vm.itemsPerPage,"label":"Items per page","type":"number","min":"-1","max":"15","hide-details":""},on:{"update:model-value":function($event){_vm.itemsPerPage = parseInt($event, 10)}}})],1)]},proxy:true}])}),_c('v-pagination',{attrs:{"circle":"","length":_vm.pageCount,"total-visible":"10"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }