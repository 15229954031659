<template>        
    <!-- <v-layout fill-height> -->
        <v-container>
        <v-layout>         
        <v-card outlined color="transparent">
            <v-navigation-drawer hide-overlay permanent clipped>
                <template v-slot:prepend>
                    <v-list-item two-line>
                        <v-list-item-avatar>
                            <img src="https://cdn.vuetifyjs.com/images/john.jpg">
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title>Tomáš Huplík</v-list-item-title>
                            <v-list-item-subtitle><small>tomas.huplik@gmail.com</small></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </template>

                <v-divider></v-divider>

                <v-list dense nav>
                    <v-list-item link v-for="item in items" :key="item.title" :to="item.route" @click.native="item.action && item.action()">
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ $t("account." + item.title) }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <template>
                    </template>
                </v-list>
            </v-navigation-drawer>
        </v-card>

        <v-main primary class="d-flex align-top justify-center pa-5" style="min-height: 300px;">
            <router-view name="account"></router-view>
        </v-main>

    </v-layout>
</v-container> 
</template>
<script>

export default {
    name: 'Homepage',
    components: {

    },
    data() {
        return {
            items: [
                { title: 'home', icon: 'mdi-home-city', route: '/account/home' },
                { title: 'credit', icon: 'mdi-currency-usd', route: '/account/credit' },
                { title: 'vouchers', icon: 'mdi-heart', route: '/account/vouchers' },
                { title: 'orders', icon: 'mdi-cart', route: '/account/orders' },
                { title: 'tickets', icon: 'mdi-ticket', route: '/account/tickets' },
                { title: 'profile', icon: 'mdi-cog', route: '/account/profile' },
                { title: 'password', icon: 'mdi-account', route: '/account/password' },
                {
                    title: 'logout',
                    icon: 'mdi-logout',
                    route: "/",
                    action: () => this.logout()
                }
            ],
        }
    },
    methods:{
        logout(){            
            this.$store.dispatch("logout");
        }
    }
}
</script>
