import axios from 'axios';
import router from '../router';
import store from "../store/index.js";

const instance = axios.create({
    baseURL: process.env.VUE_APP_ROOT_API + process.env.VUE_APP_API_ROOT
});

//instance.interceptors.params = {};
instance.interceptors.request.use(config => {
    //config.params["myparam"] = false;
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.common['Authorization'] = "Bearer " + token
    }

    // for every request start the progress
    return config;

}, function (error) {
    // Request error handling.
    return Promise.reject(error);
});

instance.interceptors.response.use(response => {
    if (response.data.errors && response.data.errors.length) {
        return Promise.reject(response.data);
    }
    return response;
}, function (error) {
    if (error.response && error.response.status === 401) {                
        if (store.getters['isLoggedIn']) {
            store.dispatch('logout');
            router.push('/login?redirect=' + router.currentRoute.fullPath);          
        }        
    }
    else if (error.response && error.response.status === 404) {
        store.commit('globalNotify', { type: 'warning', title: 'Error', message: error.response.data.message || 'This action could not be completed.' });
    }
    else if (error.response && (error.response.status === 422 || error.response.status === 400)) {
        // temporarily is handled outside in a form, we need to access VeeValidate context somehow to get it working from here
        return Promise.reject(error.response.data);
    }
    else if (error.response && error.response.status === 500) {
        store.commit('globalNotify', { type: 'warning', title: 'Error', message: 'Internal server error' });
    } else {
        let message = error.message || error.response.data.error.message || error.response.statusText;
        store.commit('globalNotify', { type: 'danger', title: 'Error', message: message });
    }

    return Promise.reject(error.response);
});

export default instance